import {
  Flex,
  Text,
  Icon,
  HStack,
  VStack,
  useMediaQuery,
  Image,
} from '@chakra-ui/react';
import React from 'react';
import { FaTwitter, FaLinkedin } from 'react-icons/fa';
import { Link } from '@chakra-ui/react';
import moment from 'moment';
import { generatePath } from '../../lib/generatePath';

const Footer = () => {
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)');

  const currentYear = moment().format('YYYY');

  return (
    <Flex p={20} background="#cf0032" direction="column">
      <Flex
        direction={isLargerThan600 ? 'row' : 'column'}
        justifyContent={isLargerThan600 ? 'space-between' : 'center'}
      >
        {/* Column 1 */}
        <Flex
          direction="column"
          alignItems={isLargerThan600 ? 'flex-start' : 'center'}
          justifyContent="space-between"
          mb={isLargerThan600 ? '0px' : '50px'}
        >
          <Image src={`/ap-logo-white.png`} alt="logo" width={50} height={50} />
          {isLargerThan600 && (
            <Text fontSize="xs" fontWeight="300" color="white">
              © Anders Pink 2022
            </Text>
          )}
        </Flex>
        {/* Column 2 */}
        <Flex direction="column">
          <VStack alignItems={isLargerThan600 ? 'start' : 'center'}>
            <Link
              href={generatePath('/client-success-stories')}
              _hover={{ textDecoration: 'none' }}
            >
              <Text fontWeight="300" color="white">
                Success Stories
              </Text>
            </Link>
            <Link
              href={generatePath('/about')}
              _hover={{ textDecoration: 'none' }}
            >
              <Text fontWeight="300" color="white">
                About
              </Text>
            </Link>
            <Link href="/meet-the-team" _hover={{ textDecoration: 'none' }}>
              <Text fontWeight="300" color="white">
                Meet the team
              </Text>
            </Link>
            <Link href="/blog" _hover={{ textDecoration: 'none' }}>
              <Text fontWeight="300" color="white">
                Blog
              </Text>
            </Link>
            <Link
              href={generatePath('/product-briefing-bundles')}
              _hover={{ textDecoration: 'none' }}
            >
              <Text fontWeight="300" color="white">
                Pre-defined bundles
              </Text>
            </Link>
            <Link
              href={generatePath('/product-multi-language')}
              _hover={{ textDecoration: 'none' }}
            >
              <Text fontWeight="300" color="white">
                Multi-language features
              </Text>
            </Link>
            <Link
              href={generatePath('/integrations')}
              _hover={{ textDecoration: 'none' }}
            >
              <Text fontWeight="300" color="white">
                APIs and integrations
              </Text>
            </Link>
            <Link
              href={generatePath('/partners')}
              _hover={{ textDecoration: 'none' }}
            >
              <Text fontWeight="300" color="white">
                Partner showcase
              </Text>
            </Link>
          </VStack>
        </Flex>
        {/* Column 3 */}
        <Flex direction="column" mt={isLargerThan600 ? '0px' : '50px'}>
          <VStack alignItems={isLargerThan600 ? 'start' : 'center'}>
            {/* <Link
              href={generatePath('/cc/platforms')}
              _hover={{ textDecoration: 'none' }}
            >
              <Text fontWeight="300" color="white">
                Complete Curation - Platforms
              </Text>
            </Link>
            <Link
              href={generatePath('/cc/enterprise')}
              _hover={{ textDecoration: 'none' }}
            >
              <Text fontWeight="300" color="white">
                Complete Curation - Enterprise
              </Text>
            </Link> */}
            <Link
              href={generatePath('/wc/platforms')}
              _hover={{ textDecoration: 'none' }}
            >
              <Text fontWeight="300" color="white">
                Web Curation - Platforms
              </Text>
            </Link>
            <Link
              href={generatePath('/wc/enterprise')}
              _hover={{ textDecoration: 'none' }}
            >
              <Text fontWeight="300" color="white">
                Web Curation - Enterprise
              </Text>
            </Link>
            <Link
              href={generatePath('/content-skills-mapper')}
              _hover={{ textDecoration: 'none' }}
            >
              <Text fontWeight="300" color="white">
                Content to Skills Mapper
              </Text>
            </Link>
          </VStack>
        </Flex>
        {/* Column 4 */}
        <Flex direction="column" mt={isLargerThan600 ? '0px' : '50px'}>
          <VStack alignItems={isLargerThan600 ? 'start' : 'center'}>
            <Link
              href={generatePath('/contact')}
              _hover={{ textDecoration: 'none' }}
            >
              <Text fontWeight="300" color="white">
                Contact
              </Text>
            </Link>
            <Link
              href={generatePath('/support')}
              _hover={{ textDecoration: 'none' }}
            >
              <Text fontWeight="300" color="white">
                Support
              </Text>
            </Link>
            <Link
              href={generatePath('/privacy-policy')}
              _hover={{ textDecoration: 'none' }}
            >
              <Text fontWeight="300" color="white">
                Privacy policy
              </Text>
            </Link>
            <Link
              href={generatePath('/cookie-policy')}
              _hover={{ textDecoration: 'none' }}
            >
              <Text fontWeight="300" color="white">
                Cookie policy
              </Text>
            </Link>
            <Text fontWeight="900" color="white">
              Follow Us
            </Text>
            <HStack>
              <Link
                href="https://twitter.com/anderspink"
                _hover={{ textDecoration: 'none' }}
                aria-label="Twitter"
                target="_blank"
              >
                <Icon as={FaTwitter} w={8} h={8} color="white" />
              </Link>
              <Link
                href="https://www.linkedin.com/company/anders-pink/"
                _hover={{ textDecoration: 'none' }}
                aria-label="Linkedin"
                target="_blank"
              >
                <Icon as={FaLinkedin} w={8} h={8} color="white" />
              </Link>
            </HStack>
          </VStack>
        </Flex>
        {/* Column 5 */}
        <Flex direction="column" mt={isLargerThan600 ? '0px' : '50px'}>
          <VStack spacing={50}>
            <Image alt="l" src={`/2020_iil_award.png`} width={100} maxH={100} />
            <Image
              alt="l"
              src={`/NQA_ISO27001_CMYK.jpg`}
              width={100}
              maxH={200}
            />
          </VStack>
        </Flex>

        {!isLargerThan600 && (
          <Text
            mt={isLargerThan600 ? '0px' : '20px'}
            textAlign="center"
            fontSize="xs"
            color="white"
            fontWeight="300"
          >
            © Anders Pink {currentYear}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default Footer;
