export const generatePath = (path: string) => {
  // Retrieve the isFull value from localStorage if it is available
  const isFull =
    typeof localStorage !== 'undefined'
      ? localStorage.getItem('isFull') || process.env.NEXT_PUBLIC_FULL_WEBSITE
      : process.env.NEXT_PUBLIC_FULL_WEBSITE;

  if (isFull === 'false' && path === '/blog') {
    return 'https://blog.anderspink.com';
  } else if (isFull === 'false') {
    return 'https://app.anderspink.com' + path;
  } else {
    return path;
  }
};
